import { Drawer } from '@components/common';
import BrandInfo from '../Aside/BrandInfo';
import AsideList from '../Aside/AsideList';
import Profile from '../Aside/Profile';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import { routes, customerRoute } from 'constants/routes';
interface Properties {
    open?: boolean;
    openAsidNew?: any;
}
const AsideMobile = ({ open, openAsidNew }: Properties) => {
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    return (
        <>
            <MainWrapper>
                <Drawer custom={true} left={true} active={open} onClose={openAsidNew}>
                    <Row>
                        <AppointmentContent>
                            <HeaderContainer>
                                <BrandInfo />

                                <CloseButton onClick={openAsidNew}>
                                    <CloseIcon className="fal fa-times"></CloseIcon>
                                </CloseButton>
                            </HeaderContainer>
                            <DrawerBody>
                                <Wrapper>
                                    <Row>
                                        <List lg={12}>
                                            <AsideList
                                                routes={
                                                    _userData?.user_login == 'customer'
                                                        ? customerRoute
                                                        : routes
                                                }
                                                openAsidNew={openAsidNew}
                                            />
                                        </List>
                                    </Row>
                                </Wrapper>
                            </DrawerBody>
                            <Wrapper>
                                <Row>
                                    <Profile />
                                </Row>
                            </Wrapper>
                        </AppointmentContent>
                    </Row>
                </Drawer>
            </MainWrapper>
        </>
    );
};

const AppointmentContent = styled(Col)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #1a202c;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1rem 1rem 0.5rem;
    min-height: 70px;
    align-items: center;
    justify-content: space-between;
`;
const CloseButton = styled.div`
    margin-left: 1.5rem;
    cursor: pointer;
`;

const Icon = styled.i`
    font-size: 2.25rem;
    line-height: 2.5rem;
`;

const CloseIcon = styled(Icon)`
    color: #fff6;
`;

const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding-top: 0.5rem;
    padding: 0 1.75rem;
    overflow: hidden;
`;
const MainWrapper = styled(Container)`
    ${media.xs`
    display: flex;
  `}
    ${media.sm`
    display: flex;
  `}
    ${media.md`
	display: flex;
  `}
    ${media.lg`
    display:none;
  `}
`;
const Wrapper = styled(Container)`
    padding: 0;
`;
const List = styled(Col)`
    width: 100%;
    flex: 1 1 100%;
    padding: 0rem;
    margin: 0rem;
`;

export default AsideMobile;
