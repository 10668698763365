import React, { useEffect, useState } from 'react';

export interface DrawerProps {
    active?: boolean;
    custom?: boolean;
    left?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    children?: React.ReactNode;
}

const Drawer = ({ active, custom, left, onOpen, onClose, children }: DrawerProps) => {
    const [open, setOpen] = useState(active);
    const [rendered, setRendered] = useState(active);

    const closeDrawer = () => {
        setRendered(true);
        setOpen(false);
        setRendered(false);
        if (onClose) onClose();
    };

    const openDrawer = () => {
        setRendered(true);
        setOpen(false);

        setTimeout(() => {
            setOpen(true);
        }, 20);
    };

    useEffect(() => {
        if (active && !open) openDrawer();
        if (!active && open) closeDrawer();
    });

    return rendered ? (
        <>
            <div
                className={`${left ? 'drawer-left' : 'drawer-right'} drawer ${
                    open ? 'drawer--open' : ''
                }`}>
                {custom ? (
                    children
                ) : (
                    <>
                        <div className="drawer--header">
                            <button className="drawer--closeBtn" onClick={() => closeDrawer()}>
                                <i className="fal fa-times"></i>
                            </button>
                            <div className="drawer--heading">Requested Appointments</div>
                        </div>
                        <div className="drawer--body">{children}</div>
                    </>
                )}
            </div>
            <div className="page-cover" onClick={() => closeDrawer()}></div>
        </>
    ) : null;
};

export default Drawer;
