import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { NewAppointmentDrawer, EditAppointmentDrawer } from '@components/appointments';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { v4 as uuid } from 'uuid';
import { ICustomer } from 'interfaces/customer.interface';
import { IService } from 'interfaces/service.interface';
import useTimeIndicator from '../BookingsCalendar/useTimeIndicator';
import moment from 'moment';
import { AppointmentStatusEnum, IAppointment } from 'interfaces/appointment.interface';
import { LoaderOverlay, useSnackbar } from '@components/common';
import useRouter from 'hooks/router';
import ModifyAppointmentModal from '../Modal/ModifyAppointmentModal';
import './Calender.css';
import jquery from 'jquery';
import { api } from 'helpers/auth-axios';
import BlockModel from '../Modal/BlockModel';
import DeleteModel from '@components/common/DeleteModel/DeleteModel';
import ConflictModal from '@components/appointments/Modal/ConflictModal';
import Pusher from 'pusher-js';
// this helps TypeScript to understand jQuery best !!!  otherwise It will confused .

export interface IEvent {
    id: string;
    start: string;
    end: string;
    service: IService;
    customer: ICustomer;
}

interface IProps {
    numServiceProvidersPerPage: number;
    setDateRange: any;
    teamCalendarDate: any;
    setTeamCalendarDate: any;
    pickDate: any;
    calendar_size: any;
}
let serviceProviderIds: any = [];

const TeamCalendar = forwardRef(
    (
        {
            numServiceProvidersPerPage,
            setDateRange,
            teamCalendarDate,
            setTeamCalendarDate,
            pickDate,
            calendar_size,
        }: IProps,
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            next() {
                next();
            },
            prev() {
                prev();
            },
            today() {
                today();
            },
            changeView(view?: string) {
                changeView(view);
            },
        }));

        const $: JQueryStatic = jquery;
        const router = useRouter();
        const [serviceProvider, setServiceProvider] = useState<any>([]);
        const weekClasses = [
            '.fc-day-sun',
            '.fc-day-mon',
            '.fc-day-tue',
            '.fc-day-wed',
            '.fc-day-thu',
            '.fc-day-fri',
            '.fc-day-sat',
        ];

        const [preDay, setPreDay] = useState<any>('');

        const [loading, setLoading] = useState(false);
        const [selectedServiceProvider, setSelectedServiceProvider] = useState<any>();
        const [confirmation, setConfirmation] = useState({
            show: false,
            id: '',
            start: moment().format(),
            end: moment().format(),
            changingServiceProvider: false,
            paymentInfoProvided: false,
            callback: () => {},
        });
        const calendarRef = useRef<FullCalendar>(null);
        const [serviceProviderCount, setServiceProviderCount] = useState<any>();
        const [pageNo, setPageNo] = useState<any>(1);
        // set service provider when a time slot for a new appointment is selected
        const [serviceProviderId, setServiceProviderId] = useState<any>();
        const [workingHours, setWorkingHours] = useState<any>([]);
        const [id, setId] = useState<any>();
        const [newAppointment, setNewAppointment] = useState<any>({
            show: false,
            id: '',
            start: preDay !== undefined && preDay,
            end: preDay !== undefined && preDay,
        });
        const [indicate] = useTimeIndicator();
        const [showBlockDeleteModel, setShowBlockDeleteModel] = useState<any>(false);
        const [appointmentId, setAppointmentId] = useState<any>();
        const [showBlockModel, setShowBlockModel] = useState<any>(false);
        const [openSnackbar] = useSnackbar();
        const [blockDate, setBlockDate] = useState<any>();
        const [conflictDates, setConflictDates] = useState<string[]>([]);
        const [showConflictModal, setShowConflictModal] = useState(false);
        const [appointmentData, setAppointmentData] = useState<any[]>([]);
        // const { business }: { business: IBusiness } = useAppSelector(state => state.business);
        var _userData = JSON.parse(localStorage.getItem('user') || '{}');
        useEffect(() => {
            indicate();
        });
        useEffect(() => {
            changeView('timeGridWeek');
        }, []);
        useEffect(() => {
            if (serviceProvider.length !== undefined) {
                teamsProfileShow();
                showArrows();
            }
        }, [serviceProvider]);

        useEffect(() => {
            getServiceProviderAppointments(pageNo);
        }, [preDay]);

        useEffect(() => {
            calendarDate(teamCalendarDate);
        }, [teamCalendarDate]);

        // get indices of service provider IDs (columns in the FullCalendar were meant to be
        // days, so we access service providers that as days on the calendar week view)
        const getServiceProviderIdForEvent = (event: any) => {
            let now = moment(event.start);
            let sunday = now.clone().weekday(0);
            let start = moment(sunday);
            let duration = moment.duration(now.diff(start));
            let days = duration.asDays();
            let indexNumber: any = days;
            if (id.length === undefined) return null;
            return id[JSON.parse(indexNumber)]?._id;
        };

        /*
         * Setup Calendar Date
         */
        const calendarDate = (teamCalendarDate: any) => {
            setPreDay(teamCalendarDate);
            if (teamCalendarDate && teamCalendarDate != '') {
                const calendarApi = calendarRef?.current?.getApi();
                calendarApi?.gotoDate(moment(teamCalendarDate).format('YYYY-MM-DD'));
                changeView('timeGridWeek');
            }
        };
        const getWorkingHours = () => {
            let businessHours: any[] = [];
            if (workingHours[0] !== undefined) {
                workingHours[0].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [0],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[1] !== undefined) {
                workingHours[1].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [1],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[2] !== undefined) {
                workingHours[2].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [2],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[3] !== undefined) {
                workingHours[3].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [3],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[4] !== undefined) {
                workingHours[4].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [4],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[5] !== undefined) {
                workingHours[5].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [5],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            if (workingHours[6] !== undefined) {
                workingHours[6].map((hours: any) => {
                    businessHours.push({
                        daysOfWeek: [6],
                        startTime: hours.startTime,
                        endTime: hours.endTime,
                    });
                });
            }
            return businessHours;
        };

        const removeAllEvent = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.removeAllEvents();
            }
        };

        /*
         * Reloads Service Provider Info & Appointments
         */
        const getServiceProviderAppointments = (_pageNo: any) => {
            removeAllEvent();

            if (preDay !== '') {
                setServiceProvider([]);
                setLoading(true);
                setAppointmentData([]);
                let date = moment(teamCalendarDate).format();
                api.post(
                    `/appointments/serviceprovider/daily?page=${_pageNo}&pageSize=${numServiceProvidersPerPage}`,
                    { date: date }
                ).then((res: any) => {
                    if (res.error) {
                        return;
                    }
                    removeAllEvent();
                    setServiceProviderCount(
                        res.data !== undefined && res.data.serviceProviders.total
                    );
                    setAppointmentData(res.data.appointments.serviceProviderAppointments);
                    setWorkingHours(res.data.appointments.serviceProviderWorkingHours);
                    setId(res.data.serviceProviders.serviceProvider.map((d: any) => d));
                    let array: any = [];
                    serviceProviderIds = [];
                    res.data.serviceProviders.serviceProvider.map((d: any) => {
                        array.push(d);
                        serviceProviderIds.push(d._id);
                    });
                    setServiceProvider(array);
                    setLoading(false);
                });
            }
        };
        useEffect(() => {
            if (appointmentData?.length) {
                appointmentData?.map((appointment: any) => {
                    addEvent({
                        id: appointment?._id,
                        start: moment(appointment?.booked_from_iso).format(),
                        end: moment(appointment?.booked_till_iso).format(),
                        service: appointment?.service,
                        customer: appointment?.customer,
                        serviceProvider: serviceProvider,
                        is_blocked: appointment?.is_blocked,
                        block_reason: appointment?.block_reason,
                        is_vip: appointment?.is_vip,
                        status: appointment?.status,
                        payment: appointment?.payment,
                        payment_intent_id: appointment?.payment_intent_id,
                        payment_method_id: appointment?.payment_method_id,
                        conversion_tracking: appointment?.conversion_tracking,
                        customer_note: appointment?.customer_note,
                    });
                });
            }
        }, [appointmentData]);

        const showArrows = () => {
            if (serviceProvider.length != 0) {
                var pageCount = Math.ceil(serviceProviderCount / numServiceProvidersPerPage);
                var tableHead: any = $(`table.fc-col-header`);
                $('.arrowRight,.arrowLeft').remove();
                if (pageNo == 1 && pageCount == 1) {
                    // don't render arrows if we're on page 1 of 1
                    $('.arrowRight,.arrowLeft').remove();
                } else if (pageNo < pageCount) {
                    if (pageNo == 1) {
                        // if we're on page one, don't render "previous" arrow
                        tableHead.prepend(` <span class="arrowRight"></span>`);
                    } else {
                        // if we're not on the last page, both arrows
                        tableHead.prepend(
                            `<span class="arrowLeft"></span> <span class="arrowRight"></span>`
                        );
                    }
                } else if (pageNo > 1 && pageNo == pageCount) {
                    // if we're on the last page, don't rander "next" arrow
                    tableHead.prepend(`<span class="arrowLeft"></span>`);
                }
            }
        };

        /*
         * Modifies Week Headers to Present Service Provider Info / Picture
         */
        const teamsProfileShow = () => {
            var week = 0;
            for (var i = 0; i < numServiceProvidersPerPage; i++) {
                var teamHeader: any = $(`th${weekClasses[week]} div a`);
                teamHeader.css({
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                });

                $(`th.fc-day-today`).css('border-bottom', '1px solid #dddddd');
                $(`td.fc-timegrid-col`).addClass('fc-day-today');

                $(`.fc-timegrid-now-indicator-container`).addClass('fullLine');
                $(`.fc-timegrid-now-indicator-line`).addClass('fullLionWidth');

                teamHeader.text('');
                if (serviceProvider.length != 0 ? serviceProvider[i] != undefined : '') {
                    teamHeader.prepend(
                        `<img style="width:3rem;height:3rem;border-radius: 100%;" src=https://profile-images-barberone-s3.s3.amazonaws.com/${serviceProvider[i].photo} /> <span id=${serviceProvider[i]._id} class="providerName">${serviceProvider[i].name}</span>`
                    );
                }

                week = week + 1;
            }
        };

        useEffect(() => {
            $('.arrowRight').on('click', function () {
                nextPage();
            });

            $('.arrowLeft').on('click', function () {
                prevPage();
            });
        });

        const nextPage = () => {
            setPageNo(1 + pageNo);
            getServiceProviderAppointments(1 + pageNo);
        };
        const prevPage = () => {
            setPageNo(pageNo - 1);
            getServiceProviderAppointments(pageNo - 1);
        };

        const next = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                var day: any = '';
                if (preDay != '') {
                    day = moment(preDay).format();
                } else {
                    day = moment().format();
                }
                var nextDay: any = moment(day).add(1, 'd').format();

                setPreDay(nextDay);
                setTeamCalendarDate(nextDay);
                setDateRange(moment(nextDay).format('ddd, MMMM DD, YYYY'));
                if (nextDay != '') {
                    calendarApi.gotoDate(moment(nextDay).format('YYYY-MM-DD'));
                }
                setTimeout(function () {
                    teamsProfileShow();
                }, 0);
            }
        };

        const prev = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                var day: any = '';
                if (preDay != '') {
                    day = moment(preDay).format();
                } else {
                    day = moment().format();
                }
                var newDay: any = moment(day).subtract(1, 'd').format();
                setPreDay(newDay);
                setTeamCalendarDate(newDay);
                setDateRange(moment(newDay).format('ddd, MMMM DD, YYYY'));
                if (newDay != '') {
                    calendarApi.gotoDate(moment(newDay).format('YYYY-MM-DD'));
                }
                setTimeout(function () {
                    teamsProfileShow();
                }, 0);
            }
        };

        const today = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                var newDay = moment().format();
                setPreDay(newDay);
                setTeamCalendarDate(newDay);
                setDateRange(moment(newDay).format('ddd, MMMM DD, YYYY'));
                if (newDay != '') {
                    calendarApi.gotoDate(moment(newDay).format('YYYY-MM-DD'));
                }
                setTimeout(function () {
                    teamsProfileShow();
                }, 0);
            }
        };

        /*
         * Is this used to "refresh" the calendar?
         */
        const changeView = (view?: string) => {
            if (calendarRef.current !== null) {
                //const calendarApi = calendarRef.current.getApi();
                //if (view) calendarApi.changeView(view);
                const d = moment(teamCalendarDate).format();
                setDateRange(moment(d).format('ddd, MMMM DD, YYYY'));
            }
            // reload service provider and appointment data
            getServiceProviderAppointments(pageNo);
        };

        const addEvent = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.addEvent(event);
            }
        };

        const editEvent = (event: any) => {
            removeEvent(event);
            addEvent(event);
        };

        const removeEvent = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.getEventById(event.id)?.remove();
            }
        };
        const getIndex = () => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
            }
        };
        const deleteBlock = () => {
            setLoading(true);
            api.delete(`/appointments/${appointmentId}`)
                .then(() => {
                    setLoading(false);
                    openSnackbar('Block deleted successfully!');
                    setShowBlockDeleteModel(false);
                })
                .catch((res: any) => {
                    openSnackbar('Failed to delete Block!');
                });
        };
        $('.fc-timegrid-slot-lane,.fc-timegrid-slot-label').hover(function () {
            var getDefaultTime = $(this).attr('data-time');
            var formatedTime = moment(
                moment().format() + ' ' + getDefaultTime,
                'YYYY-MM-DD HH:mm:ss'
            ).format(_userData.user.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm');
            $(this).attr('data-time', formatedTime);
        });

        const removeEventFromPusher = (event: any) => {
            if (calendarRef.current !== null) {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.getEventById(event._id)?.remove();
            }
        };
        const renderEventContent = (arg: any) => {
            const props = arg.event.extendedProps;
            const duration = moment
                .duration((arg.event.end as any) - (arg.event.start as any))
                .asMinutes();
            return (
                <div
                    className={`${props.serviceProvider} booking_event ${
                        props.is_blocked == true ? 'block_bg' : ''
                    } service-color-${props.service?.color}--bg`}>
                    <div
                        className={`booking_eventBorder service-color-${props?.service?.color}--bd`}></div>
                    <div className={`booking_eventDetails`}>
                        <div className="booking_serviceCustomer">
                            {props.payment && <i className="far fa-usd-circle "></i>}{' '}
                            {props.is_vip == true && <>vip - </>}
                            {props.is_blocked === true
                                ? props.block_reason !== ''
                                    ? props.block_reason
                                    : 'Block'
                                : ''}{' '}
                            {props.customer
                                ? props.customer?.firstname?.substring(0, 10)
                                : props.is_blocked === false && 'customer removed'}{' '}
                            {props.customer?.lastname?.substring(0, 10)}{' '}
                            {props.is_blocked === true
                                ? ''
                                : props?.service === null
                                ? '(' + 'service removed' + ')'
                                : duration <= 15
                                ? ''
                                : '(' + props?.service?.name?.substring(0, 10) + ')'}
                            {props?.status === AppointmentStatusEnum.CANCELLED ? (
                                <div
                                    className="booking_serviceCustomer"
                                    style={{
                                        color: 'red',
                                        textTransform: 'capitalize',
                                        fontSize: '0.7rem',
                                    }}>
                                    {props?.status}
                                </div>
                            ) : (
                                props.is_blocked === false && (
                                    <div
                                        className="booking_serviceCustomer"
                                        style={{
                                            textTransform: 'capitalize',
                                            fontSize: '0.7rem',
                                        }}>
                                        {props?.status}
                                    </div>
                                )
                            )}
                        </div>
                        {duration <= 15 ? (
                            <div
                                className={`${
                                    _userData.user?.business_id?.time_select === '12'
                                        ? 'booking_week_time'
                                        : 'booking_time'
                                } `}>
                                {/* {moment(arg.event.startStr).format('hh:mm a')} -{' '}
                            {moment(arg.event.endStr).format(
                                _userData?.user?.business_id?.time_select === '12'
                                    ? 'hh:mm a'
                                    : 'HH:mm'
                            )} */}
                            </div>
                        ) : (
                            <div
                                className={`${
                                    _userData.user?.business_id?.time_select === '12'
                                        ? 'booking_week_time'
                                        : 'booking_time'
                                } `}>
                                {moment(arg.event.startStr).format(
                                    _userData.user?.business_id?.time_select === '12'
                                        ? 'hh:mm a'
                                        : 'HH:mm'
                                )}{' '}
                                -{' '}
                                {moment(arg.event.endStr).format(
                                    _userData?.user?.business_id?.time_select === '12'
                                        ? 'hh:mm a'
                                        : 'HH:mm'
                                )}
                            </div>
                        )}
                    </div>
                    {(props?.conversion_tracking || props?.customer_note) && (
                        <div className={`booking_eventDetails`}>
                            {props?.conversion_tracking && <i className="fas fa-ad "></i>}
                            {props?.customer_note && <i className="fas fa-comment "></i>}
                        </div>
                    )}
                </div>
            );
        };

        const appointmentHasServiceProviderInCurrentView = (pusherEvent: any) => {
            if (!pusherEvent) {
                return false;
            }
            let eventServiceProviderId;
            // sometimes serviceProvider will be the full object sometimes just ID string
            if (typeof pusherEvent.serviceProvider === 'string') {
                eventServiceProviderId = pusherEvent.serviceProvider;
            } else {
                eventServiceProviderId = pusherEvent.serviceProvider?._id;
            }
            return serviceProviderIds.includes(eventServiceProviderId);
        };

        const alignEventDateWithServiceProvider = (pusherEvent: any) => {
            if (calendarRef.current === null) {
                return;
            }

            let eventServiceProviderId;
            // sometimes serviceProvider will be the full object sometimes just ID string
            if (typeof pusherEvent.serviceProvider === 'string') {
                eventServiceProviderId = pusherEvent.serviceProvider;
            } else {
                eventServiceProviderId = pusherEvent.serviceProvider?._id;
            }

            // get the date of the first column
            const viewStartDate = calendarRef.current.getApi().view.currentStart;
            const serviceProviderIndex = serviceProviderIds.indexOf(eventServiceProviderId); // which "column" is service provider in the current calendar view?
            if (serviceProviderIndex === -1) {
                // the provider ID wasn't found
                console.error(
                    '[alignEventDateWithServiceProvider] Service provider ID wasnt found in current view'
                );
                return;
            }
            // use the service provider's ID index as an index for which day of the week to render events on
            const dateToRenderEventOn = moment(viewStartDate)
                .add(serviceProviderIndex, 'd')
                .format('YYYY-MM-DD');
            const aptStartTime = moment(pusherEvent.booked_from_iso).format('HH:mm:ssZ');
            const aptEndTime = moment(pusherEvent.booked_till_iso).format('HH:mm:ssZ');
            const newStartDatetime = moment(`${dateToRenderEventOn}T${aptStartTime}`).format();
            const newEndDatetime = moment(`${dateToRenderEventOn}T${aptEndTime}`).format();
            return {
                ...pusherEvent,
                booked_from_iso: newStartDatetime,
                booked_from: newStartDatetime,
                booked_till_iso: newEndDatetime,
                booked_till: newEndDatetime,
            };
        };

        //Pusher
        useEffect(() => {
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
                cluster: 'ap2',
            });

            let businessId = _userData?.user?.business_id?._id;
            const channel = pusher.subscribe(businessId);

            channel.bind('barberOne', function (data: any) {
                if (calendarRef.current !== null) {
                    const calendarApi = calendarRef.current.getApi();
                    const existingEventIds: any = calendarApi
                        .getEvents()
                        .map((calendarEvents: any) => calendarEvents._def.publicId);
                    if (data?.rescheduleAppointment) {
                        const event = data.rescheduleAppointment;

                        // make sure service provider is in current view
                        if (!appointmentHasServiceProviderInCurrentView(event)) {
                            return;
                        }

                        // modify event date so it will render correctly in FullCalendar
                        const alignedEvent = alignEventDateWithServiceProvider(event);

                        editEvent({
                            id: alignedEvent._id,
                            start: moment(alignedEvent.booked_from_iso).format(),
                            end: moment(alignedEvent.booked_till_iso).format(),
                            service: alignedEvent.service,
                            customer: alignedEvent.customer,
                            is_blocked: alignedEvent.is_blocked,
                            block_reason: alignedEvent.block_reason,
                            is_vip: alignedEvent.is_vip,
                            status: alignedEvent.status,
                            payment: alignedEvent.payment,
                            conversion_tracking: alignedEvent.conversion_tracking,
                            customer_note: alignedEvent.customer_note,
                        });
                    }
                    if (data?.cancelledAppointment) {
                        const event = data.cancelledAppointment;

                        // make sure service provider is in current view
                        if (!appointmentHasServiceProviderInCurrentView(event)) {
                            return;
                        }

                        // modify event date so it will render correctly in FullCalendar
                        const alignedEvent = alignEventDateWithServiceProvider(event);

                        editEvent({
                            id: alignedEvent._id,
                            start: moment(alignedEvent.booked_from_iso).format(),
                            end: moment(alignedEvent.booked_till_iso).format(),
                            service: alignedEvent.service,
                            customer: alignedEvent.customer,
                            is_blocked: alignedEvent.is_blocked,
                            block_reason: alignedEvent.block_reason,
                            is_vip: alignedEvent.is_vip,
                            status: alignedEvent.status,
                            payment: alignedEvent.payment,
                            conversion_tracking: alignedEvent.conversion_tracking,
                            customer_note: alignedEvent.customer_note,
                        });
                    }
                    if (data?.deleteAppointment) {
                        data?.deleteAppointment?.map((data: any) => {
                            removeEventFromPusher(data);
                        });
                    }
                    if (data?.newAppointment?.length) {
                        data?.newAppointment?.map((event: any) => {
                            // if the new appointment's date does match the date being viewed in Team Calendar, do nothing
                            if (
                                moment(calendarApi.getDate()).format('YYYY-MM-DD') !==
                                moment(event.booked_from).format('YYYY-MM-DD')
                            ) {
                                return;
                            }

                            // make sure service provider is in current view
                            if (!appointmentHasServiceProviderInCurrentView(event)) {
                                return;
                            }

                            // if event is already present, no need to re-add it
                            if (
                                existingEventIds.find(
                                    (existingEventId: any) => existingEventId === event._id
                                )
                            ) {
                                return;
                            }

                            // modify event date so it will render correctly in FullCalendar
                            const alignedEvent = alignEventDateWithServiceProvider(event);

                            addEvent({
                                id: alignedEvent?._id,
                                start: moment(alignedEvent?.booked_from_iso).format(),
                                end: moment(alignedEvent?.booked_till_iso).format(),
                                service: alignedEvent?.service,
                                customer: alignedEvent?.customer,
                                is_blocked: alignedEvent?.is_blocked,
                                block_reason: alignedEvent?.block_reason,
                                is_vip: alignedEvent?.is_vip,
                                status: alignedEvent?.status,
                                payment: alignedEvent?.payment,
                                conversion_tracking: alignedEvent?.conversion_tracking,
                                customer_note: alignedEvent?.customer_note,
                            });
                        });
                    }
                }
            });
        }, [pageNo, numServiceProvidersPerPage]);

        /*
         * General Utility Functions
         */

        const selectedDateIsToday = () => {
            const today = moment();
            const selectedDate = moment(teamCalendarDate);

            return selectedDate.isSame(today, 'date');
        };

        return (
            <Wrapper>
                {loading && <LoaderOverlay />}
                <Flex id="teamCalender">
                    <FullCalendar
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                            momentTimezonePlugin,
                        ]}
                        initialView="timeGrid"
                        duration={{ days: numServiceProvidersPerPage }}
                        dateAlignment="week"
                        //slotDuration={`00:${business.calendar_size}:00`}
                        slotDuration={`00:${calendar_size !== undefined ? calendar_size : '15'}:00`}
                        allDaySlot={false}
                        nowIndicator={selectedDateIsToday()}
                        slotLabelInterval="1:00"
                        height="100%"
                        headerToolbar={{
                            left: '',
                            center: '',
                            right: '',
                        }}
                        dayHeaderFormat={{
                            weekday: 'short',
                            month: 'short',
                            day: 'numeric',
                            omitCommas: false,
                        }}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: _userData.user.business_id.time_select === '12' ? true : false,
                        }}
                        scrollTime={moment().subtract(2, 'h').format('HH') + ':00'}
                        editable={true}
                        timeZone={
                            _userData?.user?.business_id
                                ? _userData.user.business_id?.timezone
                                : 'America/Chicago'
                        }
                        eventDurationEditable={true}
                        selectable={true}
                        selectMirror={true}
                        longPressDelay={150}
                        select={args => {
                            let now = moment(args.startStr);
                            let sunday = now.clone().weekday(0);
                            let saturday = now.clone().weekday(6);
                            let start = moment(sunday); //today date
                            //let end = moment(saturday); // another date
                            let duration = moment.duration(now.diff(start));
                            let days = duration.asDays();
                            let indexNumber: any = days;
                            setServiceProviderId(indexNumber);

                            /*
                             * If the selected column doesn't have a corresponding
                             * service provider, don't open the new appointment drawer
                             */
                            if (indexNumber > serviceProviderIds.length - 1) {
                                return;
                            }

                            let from_hour: any = moment(args.startStr).format('HH');
                            let from_mint: any = moment(args.startStr).format('mm');
                            let till_hours: any = moment(args.endStr).format('HH');
                            let till_mint: any = moment(args.endStr).format('mm');
                            let today: any =
                                preDay !== ''
                                    ? moment(preDay).format('YYYY-MM-DD')
                                    : moment().format('YYYY-MM-DD');
                            let day: any = today !== '' ? moment(today).format('DD') : '';
                            let year: any = today !== '' ? moment(today).format('YYYY') : '';
                            let month: any = today !== '' ? moment(today).format('MM') : '';

                            let booked_from_iso = moment(
                                year + month + day + from_hour + from_mint,
                                'YYYY MM DD HH mm'
                            );
                            let booked_till_iso = moment(
                                year + month + day + till_hours + till_mint,
                                'YYYY MM DD HH mm'
                            );
                            const id = uuid();
                            if (indexNumber != serviceProviderId) {
                                setNewAppointment({
                                    show: false,
                                });
                            }

                            setNewAppointment({
                                show: true,
                                id: id,
                                start: booked_from_iso.format(),
                                end: booked_till_iso.format(),
                            });
                            setBlockDate(booked_from_iso);
                        }}
                        businessHours={getWorkingHours()}
                        eventContent={renderEventContent}
                        eventClassNames={function (arg) {
                            return arg.event.extendedProps.classNames;
                        }}
                        eventClick={arg => {
                            arg.event._def.extendedProps.is_blocked === true
                                ? setShowBlockDeleteModel(true)
                                : router.setParams({ appointment: arg.event.id });
                            setAppointmentId(arg.event.id);
                            setNewAppointment({
                                show: false,
                            });
                        }}
                        eventDrop={(info: any) => {
                            let from_hour: any = moment(info.event.startStr).format('HH');
                            let from_mint: any = moment(info.event.startStr).format('mm');
                            let till_hours: any = moment(info.event.endStr).format('HH');
                            let till_mint: any = moment(info.event.endStr).format('mm');
                            let today: any =
                                preDay !== ''
                                    ? moment(preDay).format('YYYY-MM-DD')
                                    : moment().format('YYYY-MM-DD');
                            let day: any = today !== '' ? moment(today).format('DD') : '';
                            let year: any = today !== '' ? moment(today).format('YYYY') : '';
                            let month: any = today !== '' ? moment(today).format('MM') : '';
                            let booked_from_iso = moment(
                                year + month + day + from_hour + from_mint,
                                'YYYY MM DD HH mm'
                            );
                            let booked_till_iso = moment(
                                year + month + day + till_hours + till_mint,
                                'YYYY MM DD HH mm'
                            );
                            let prevServiceProviderId = getServiceProviderIdForEvent(info.oldEvent);
                            let selectedServiceProviderId = getServiceProviderIdForEvent(
                                info.event
                            );

                            // determine if we're changing the service provider
                            let changingServiceProvider =
                                selectedServiceProviderId !== prevServiceProviderId;

                            let paymentInfoProvided =
                                info.event.extendedProps.payment === null &&
                                info.event.extendedProps.payment_intent_id &&
                                info.event.extendedProps.payment_intent_id !== '' &&
                                info.event.extendedProps.payment_method_id &&
                                info.event.extendedProps.payment_method_id !== '';

                            setSelectedServiceProvider(
                                selectedServiceProviderId !== undefined
                                    ? selectedServiceProviderId
                                    : ''
                            );
                            if (
                                selectedServiceProviderId != '' &&
                                selectedServiceProviderId != undefined
                            ) {
                                setConfirmation({
                                    show: true,
                                    id: info.event.id,
                                    start: moment(booked_from_iso).format(),
                                    end: moment(booked_till_iso).format(),
                                    changingServiceProvider: changingServiceProvider,
                                    paymentInfoProvided: paymentInfoProvided,
                                    callback: info.revert,
                                });
                            } else {
                                confirmation.callback();
                                getServiceProviderAppointments(pageNo);
                                getIndex();
                                setConfirmation(prevState => ({
                                    ...prevState,
                                    changingServiceProvider: false,
                                    paymentInfoProvided: false,
                                    show: false,
                                }));
                            }
                        }}
                        eventResize={(info: any) => {
                            let from_hour: any = moment(info.event.startStr).format('HH');
                            let from_mint: any = moment(info.event.startStr).format('mm');
                            let till_hours: any = moment(info.event.endStr).format('HH');
                            let till_mint: any = moment(info.event.endStr).format('mm');
                            let today: any =
                                preDay !== ''
                                    ? moment(preDay).format('YYYY-MM-DD')
                                    : moment().format('YYYY-MM-DD');
                            let day: any = today !== '' ? moment(today).format('DD') : '';
                            let year: any = today !== '' ? moment(today).format('YYYY') : '';
                            let month: any = today !== '' ? moment(today).format('MM') : '';
                            let booked_from_iso = moment(
                                year + month + day + from_hour + from_mint,
                                'YYYY MM DD HH mm'
                            );
                            let booked_till_iso = moment(
                                year + month + day + till_hours + till_mint,
                                'YYYY MM DD HH mm'
                            );
                            setConfirmation({
                                show: true,
                                id: info.event.id,
                                start: moment(booked_from_iso).format(),
                                end: moment(booked_till_iso).format(),
                                changingServiceProvider: false,
                                paymentInfoProvided: false,
                                callback: info.revert,
                            });
                        }}
                        ref={calendarRef}
                    />
                </Flex>
                {newAppointment.show && (
                    <NewAppointmentDrawer
                        serviceProviderId={
                            serviceProviderId !== undefined &&
                            id != undefined &&
                            id[serviceProviderId]?._id
                        }
                        id={newAppointment.id}
                        conflictDate={val => {
                            setConflictDates(val);
                        }}
                        conflictModel={(val: any) => {
                            setShowConflictModal(val);
                        }}
                        startDate={newAppointment.start}
                        endDate={newAppointment.end}
                        setBlockModel={() => {
                            setShowBlockModel(true);
                            setNewAppointment((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                        }}
                        onClose={(isAppointmentAdded: any, event: any) => {
                            setNewAppointment((prevState: any) => ({
                                ...prevState,
                                show: false,
                            }));
                            router.setParams({ appointment: '' });
                            if (!isAppointmentAdded) {
                                //  removeEvent(event);
                            }
                        }}
                        onSave={(event: any, appointment: IAppointment) => {
                            router.navigate('');
                            //removeEvent(event);
                            getServiceProviderAppointments(pageNo);
                        }}
                        onChange={(event: IEvent) => {
                            // editEvent(event);
                        }}
                    />
                )}
                {newAppointment.show == true
                    ? ''
                    : router.query.appointment && (
                          <EditAppointmentDrawer
                              getAppointments={() => {
                                  router.navigate('');
                                  getServiceProviderAppointments(pageNo);
                              }}
                              id={router.query.appointment as string}
                              onClose={() => {
                                  router.navigate('');
                                  getServiceProviderAppointments(pageNo);
                              }}
                              onChange={(event: IAppointment) => {
                                  // editEvent(event);
                              }}
                              setCalendarLoading={(value: boolean) => setLoading(value)}
                          />
                      )}
                {confirmation.show && (
                    <ModifyAppointmentModal
                        id={confirmation.id}
                        onDragDate={preDay !== undefined ? preDay : moment().format()}
                        startDate={confirmation.start}
                        endDate={confirmation.end}
                        serviceProvider={confirmation.changingServiceProvider}
                        serviceProvierData={selectedServiceProvider}
                        showServiceProviderChangeWarning={
                            confirmation.changingServiceProvider && confirmation.paymentInfoProvided
                        }
                        onSave={() => {
                            setConfirmation(prevState => ({
                                ...prevState,
                                changingServiceProvider: false,
                                paymentInfoProvided: false,
                                show: false,
                            }));
                            setSelectedServiceProvider('');
                            router.navigate('');
                            getServiceProviderAppointments(pageNo);
                        }}
                        onClose={() => {
                            confirmation.callback();
                            getServiceProviderAppointments(pageNo);
                            getIndex();
                            setConfirmation(prevState => ({
                                ...prevState,
                                changingServiceProvider: false,
                                paymentInfoProvided: false,
                                show: false,
                            }));
                            setSelectedServiceProvider('');
                        }}
                    />
                )}
                {showBlockModel && (
                    <BlockModel
                        startTime={newAppointment.start}
                        endTime={newAppointment.end}
                        serviceProviderId={
                            serviceProviderId !== undefined &&
                            id != undefined &&
                            id[serviceProviderId]._id
                        }
                        time_select={_userData?.user?.business_id?.time_select}
                        blockDate={blockDate}
                        onClose={() => {
                            router.navigate('');
                            getServiceProviderAppointments(pageNo);
                            setShowBlockModel(false);
                        }}
                    />
                )}
                {showBlockDeleteModel && (
                    <DeleteModel
                        id={''}
                        onDelete={() => {
                            setShowBlockDeleteModel(false);
                        }}
                        onClose={() => {
                            setShowBlockDeleteModel(false);
                        }}
                        name="Block"
                        content=" Are you sure you want to delete this Block?"
                        loading={loading}
                        onCLick={() => {
                            deleteBlock();
                            router.navigate('');
                            getServiceProviderAppointments(pageNo);
                        }}
                    />
                )}

                {showConflictModal == true && (
                    <ConflictModal
                        dates={conflictDates}
                        recurringConflict={showConflictModal}
                        onContinue={() => {
                            setShowConflictModal(false);
                        }}
                        onClose={() => setShowConflictModal(false)}
                    />
                )}
            </Wrapper>
        );
    }
);
const Wrapper = styled.div`
    flex-direction: column !important;
    flex: 1 1 0% !important;
    display: flex !important;
`;
const Flex = styled.div`
    flex: 1 1 0% !important;
`;

export default TeamCalendar;
