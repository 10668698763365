import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { SiSquare } from 'react-icons/si';
import { customAlphabet } from 'nanoid';
import { api } from 'helpers/auth-axios';
import useRouter from 'hooks/router';
import { IAccount, IAccountLink } from 'interfaces/stripe.interface';
import SyntaxHighlighter from '@components/common/SyntaxHighlighter/SyntaxHighlighter';
import CustomiseWidget from './CustomiseWidget';
import { getEmbededCode } from './embedCode';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
    Input,
    LoaderOverlay,
    Select,
    Switch,
    WarningModal,
    useSnackbar,
} from '@components/common';
import { businessActions } from 'actions/business';
import Button from '@components/Button';

const OnlineBooking = () => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    const [business, setBusiness] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [btnBgColor, setBtnBgColor] = useState<any>('');
    const [btnTextColor, setBtnTextColor] = useState<any>('');
    const [bookingRedirect, setBookingRedirect] = useState<any>({
        enabled: false,
        url: null,
    });

    const [showRevokeTokenPrompt, setShowRevokeTokenPrompt] = useState<boolean>(false);
    const [showUpdateSquareConnectionPrompt, setShowUpdateSquareConnectionPrompt] =
        useState<boolean>(false);
    const [showUpdateSquareLocationPrompt, setShowUpdateSquareLocationPrompt] =
        useState<boolean>(false);

    const [squarePermissionsList, setSquarePermissionsList] = useState<string[]>([]);
    const [squareLocationsList, setSquareLocationsList] = useState<any[]>([]);
    const [selectedSquareLocationId, setSelectedSquareLocationId] = useState<string>('');

    var _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const stripeIsConnected = business?.stripe_account_verified;
    const squareIsConnected = business?.square_account_verified;
    const businessHasSquareToken = !!business?.square_access_token;
    const showRevokeSquareTokenButton = businessHasSquareToken;
    const changedSelectedSquareLocation =
        businessHasSquareToken &&
        ((!business.square_location_id && !!selectedSquareLocationId) ||
            selectedSquareLocationId !== business.square_location_id);

    useEffect(() => {
        getBusiness();
    }, []);

    useEffect(() => {
        if (!business) {
            return;
        }
        // Reset fields
        setSquarePermissionsList([]);
        setSquareLocationsList([]);
        setSelectedSquareLocationId(business.square_location_id || '');
        if (businessHasSquareToken) {
            loadSquarePermissionsList();
        }
    }, [business]);

    // Once permission list loads, check to see if we have square MERCHANT_PROFILE_READ permission
    // if we do, fetch square locations
    useEffect(() => {
        if (businessHasSquareToken && squarePermissionsList?.includes('MERCHANT_PROFILE_READ')) {
            loadSquareLocationsList();
        }
    }, [squarePermissionsList]);

    useEffect(() => {
        if (router.query.status === 'stripe_return' && router.query.account_id) {
            /*
             * Handle Stripe Auth Return
             */
            router.navigate('/settings/online-booking');
            api.get('/billing/account').then(res => {
                const account: IAccount = res.data;

                // Not fully completed -> show error message
                if (!account.details_submitted) {
                    openSnackbar('Failed to integrate with Stripe. Please try again');
                } else {
                    dispatch(
                        businessActions.updateBusines(_userData?.user?.business_id?._id!, {
                            stripe_account_verified: true,
                        })
                    ).then((res: any) => {
                        getBusiness();
                        openSnackbar('Stripe integrated successfully!');
                    });
                }
            });
        } else if (
            router.query.response_type &&
            router.query.response_type == 'code' &&
            router.query.code &&
            router.query.state
        ) {
            /*
             * Handle Square OAuth Return
             */
            const CSRF_TOKEN = localStorage.getItem('squareOAuthCSRF');
            if (!CSRF_TOKEN) {
                return;
            }
            // make sure the returned CSRF token matches the one we've stored in the session
            if (CSRF_TOKEN == router.query.state) {
                // send authorization code to the backend, so it can grab the
                // access and refresh tokens
                const authorizationCode = router.query.code;
                api.post(`/businesses/${_userData?.user?.business_id._id}/connect-to-square`, {
                    authorizationCode: authorizationCode,
                })
                    .then(res => {
                        localStorage.removeItem('squareOAuthCSRF');
                        getBusiness();
                        openSnackbar('Square integrated successfully!');
                    })
                    .catch((e: any) => {
                        if (e?.response) {
                            openSnackbar(e?.response?.data?.message);
                        }
                    });
            }
        }
    }, [router.query]);

    const connectToStripe = () => {
        const stripeData = {
            id: null,
        };
        api.post('/billing/connect-to-stripe', stripeData).then((res: any) => {
            const data: IAccountLink = res.data;
            window.location.href = data.url;
        });
    };

    /*
     * Square connection flow:
     * in frontend, generate authorization URL
     * navigate to authorization URL
     * user will be sent back to this page (/settings/online-booking)
     * once they are sent back here, refresh the view so that accurate integration
     * status is displayed.
     * finally, remove "squareOAuthCSRF" from session storage
     */
    const connectToSquare = () => {
        // build authorization link
        const STG_HOST = 'connect.squareupsandbox.com';
        const PRD_HOST = 'connect.squareup.com';
        const HOST = process.env.REACT_APP_ENVIRONMENT === 'production' ? PRD_HOST : STG_HOST;
        const APP_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID;
        const PERMISSIONS =
            'CUSTOMERS_WRITE+CUSTOMERS_READ+PAYMENTS_WRITE+PAYMENTS_WRITE_SHARED_ONFILE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_READ+ORDERS_READ+ORDERS_WRITE+MERCHANT_PROFILE_READ';
        // setup csrf token generator
        const nanoid = customAlphabet('0123456789abcdef', 26);
        let CSRF_TOKEN = nanoid();
        // because square only allows you to set a single, static redirect URL
        // the user will first be redirected back to another page, before they are
        // routed back to this page.
        // To get the user to be sent back to this page, we append "-business"
        // to the CSRF token
        CSRF_TOKEN += '-business';
        // store copy of csrf token in session storage
        localStorage.setItem('squareOAuthCSRF', CSRF_TOKEN);
        const baseUrl = `https://${HOST}/oauth2/authorize`;
        const url = `${baseUrl}?client_id=${APP_ID}&scope=${PERMISSIONS}&session=false&state=${CSRF_TOKEN}`;
        window.location.href = url;
    };

    const getBusiness = () => {
        setLoading(true);
        api.get(`/businesses/${_userData?.user?.business_id?._id}`)
            .then((res: any) => {
                setLoading(false);
                _userData.user.business_id = res.data;
                localStorage.setItem('user', JSON.stringify(_userData));
                if (res.data) {
                    setBusiness(res.data);
                    if (res.data.booking_redirect) {
                        setBookingRedirect({
                            enabled: res.data.booking_redirect.enabled,
                            url: res.data.booking_redirect.url,
                        });
                    }
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const accountRemove = () => {
        dispatch(
            businessActions.updateBusines(_userData?.user?.business_id._id!, {
                stripe_account_verified: false,
            })
        ).then((res: any) => {
            getBusiness();
            openSnackbar('Stripe Account Removed Successfully!');
        });
    };

    const disconnectSquare = () => {
        dispatch(
            businessActions.updateBusines(_userData?.user?.business_id._id!, {
                square_account_verified: false,
            })
        ).then((res: any) => {
            getBusiness();
            openSnackbar('Stripe Account Removed Successfully!');
        });
    };

    const loadSquarePermissionsList = () => {
        setLoading(true);
        api.get(`/businesses/${_userData?.user?.business_id._id}/square-permissions`)
            .then((res: any) => {
                setLoading(false);
                setSquarePermissionsList(res.data);
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar('Failed to load Square permissions list.');
                getBusiness();
            });
    };

    const loadSquareLocationsList = () => {
        setLoading(true);
        api.get(`/businesses/${_userData?.user?.business_id._id}/square-locations`)
            .then((res: any) => {
                setLoading(false);
                setSquareLocationsList(res.data);
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar('Failed to load Square locations list.');
            });
    };

    const revokeSquareToken = () => {
        api.post(`/businesses/revoke-square-token`)
            .then((res: any) => {
                openSnackbar('Square Authentication Token Successfully Revoked!');
                getBusiness();
            })
            .catch((e: any) => {
                openSnackbar('Failed to revoke square access token.');
                getBusiness();
            });
    };

    const updateSquareLocationId = (squareLocationId: string) => {
        if (!squareLocationId) {
            openSnackbar('Failed to update Square Location. No location ID given.');
        }
        setLoading(true);
        api.put(`/businesses/${_userData?.user?.business_id._id}`, {
            square_location_id: squareLocationId,
        })
            .then((res: any) => {
                setLoading(false);
                openSnackbar('Successfully changed Square Location!');
                getBusiness();
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar('Failed to change Square Location. Hit "refresh" and try again.');
                getBusiness();
            });
    };

    const updateBookingRedirect = () => {
        setLoading(true);
        api.put(`/businesses/${_userData?.user?.business_id._id}`, {
            booking_redirect: bookingRedirect,
        })
            .then((res: any) => {
                setLoading(false);
                openSnackbar('Successfully changed Booking Redirect!');
                getBusiness();
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar('Failed to change Booking Redirect. Hit "refresh" and try again.');
                getBusiness();
            });
    };

    // Only show the Update button for Booking Redirect section if something has changed
    // and Redirect URL field isn't empty
    const redirectUrlChanged = business?.booking_redirect?.url != bookingRedirect.url;
    const bookingRedirectEnabledChanged =
        business?.booking_redirect?.enabled != bookingRedirect.enabled;
    const showUpdateButtonBookingRedirect =
        (!bookingRedirect.enabled && bookingRedirectEnabledChanged) ||
        (bookingRedirect.enabled && bookingRedirectEnabledChanged && bookingRedirect.url != '') ||
        (bookingRedirect.enabled && redirectUrlChanged && bookingRedirect.url != '');

    return (
        <Wrapper>
            {loading && <LoaderOverlay />}
            {business && (
                <Row>
                    <Col md={6} lg={7}>
                        <Wrapper>
                            <Row>
                                {/* @TODO Move it somewhere else */}
                                {/*business?.stripe_account_verified === false && (
                                    <Flex>
                                        <Card>
                                            <CardBody>
                                                <AlertInfo>
                                                    <AlertInfoHeading>Stripe Fees</AlertInfoHeading>
                                                    <AlertInfoText>
                                                        Stripe charges <strong>2.9%</strong> plus{' '}
                                                        <strong>30</strong> cents per transaction.
                                                    </AlertInfoText>
                                                </AlertInfo>
                                            </CardBody>
                                            <ConnectAccountButton onClick={connectToStripe}>
                                                <i className="fab fa-stripe fa-3x"></i>
                                            </ConnectAccountButton>
                                        </Card>
                                    </Flex>
                                )*/}
                                {stripeIsConnected && (
                                    <Flex>
                                        <Card>
                                            <Heading>Stripe</Heading>
                                            <Text>Manage connection to a Stripe account</Text>
                                            <RemoveAccount onClick={accountRemove}>
                                                Remove Connected Account
                                            </RemoveAccount>
                                        </Card>
                                    </Flex>
                                )}
                                <Flex>
                                    <Card>
                                        <Heading>Square</Heading>
                                        <Text>Manage connection to a Square account.</Text>
                                        <CardBody>
                                            <AlertInfo>
                                                <AlertInfoHeading>Square Fees</AlertInfoHeading>
                                                <AlertInfoText>
                                                    Square charges <strong>2.9%</strong> plus{' '}
                                                    <strong>30</strong> cents per transaction.
                                                </AlertInfoText>
                                            </AlertInfo>
                                        </CardBody>
                                        <FlexSection>
                                            {squareIsConnected ? (
                                                <RemoveAccount
                                                    onClick={disconnectSquare}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                    Disconnect
                                                </RemoveAccount>
                                            ) : (
                                                <ConnectAccountButton
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                    onClick={connectToSquare}>
                                                    <SiSquare style={{ fontSize: '2em' }} />
                                                    <span style={{ paddingLeft: '3px' }}>
                                                        Square
                                                    </span>
                                                </ConnectAccountButton>
                                            )}
                                            {showRevokeSquareTokenButton && (
                                                <RemoveAccount
                                                    onClick={() => setShowRevokeTokenPrompt(true)}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                    Revoke Token
                                                </RemoveAccount>
                                            )}
                                            {squareIsConnected &&
                                                squarePermissionsList.length > 0 &&
                                                !squarePermissionsList.includes(
                                                    'MERCHANT_PROFILE_READ'
                                                ) && (
                                                    <UpdateAccountButton
                                                        onClick={() =>
                                                            setShowUpdateSquareConnectionPrompt(
                                                                true
                                                            )
                                                        }
                                                        disabled={loading}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                        Get Locations
                                                    </UpdateAccountButton>
                                                )}
                                        </FlexSection>
                                        {squareLocationsList && squareLocationsList.length > 0 && (
                                            <>
                                                <Text>Manage Square Location</Text>
                                                <FlexSection>
                                                    {business?.square_location_id ? (
                                                        <Select
                                                            value={
                                                                squareLocationsList.find(
                                                                    (location: any) =>
                                                                        location.id ===
                                                                        business.square_location_id
                                                                )?.name
                                                            }
                                                            options={squareLocationsList.map(
                                                                (location: any) => {
                                                                    return {
                                                                        label: location.name,
                                                                        value: location.id,
                                                                    };
                                                                }
                                                            )}
                                                            onChange={(value: string) =>
                                                                setSelectedSquareLocationId(value)
                                                            }
                                                        />
                                                    ) : (
                                                        <Select
                                                            options={squareLocationsList.map(
                                                                (location: any) => {
                                                                    return {
                                                                        label: location.name,
                                                                        value: location.id,
                                                                    };
                                                                }
                                                            )}
                                                            onChange={(value: string) =>
                                                                setSelectedSquareLocationId(value)
                                                            }
                                                        />
                                                    )}
                                                    {changedSelectedSquareLocation && (
                                                        <Button
                                                            type="submit"
                                                            bgtype="secondary"
                                                            width="100px !important"
                                                            label="Set Location"
                                                            disabled={loading}
                                                            margin="5px"
                                                            ifClicked={() =>
                                                                setShowUpdateSquareLocationPrompt(
                                                                    true
                                                                )
                                                            }></Button>
                                                    )}
                                                </FlexSection>
                                            </>
                                        )}
                                    </Card>
                                </Flex>
                                <Flex>
                                    <Card>
                                        <div>
                                            <Heading>Online Bookings</Heading>
                                            <Text>A fully customizeable booking widget.</Text>
                                            <PointsContent>
                                                <AlignFlex>
                                                    <Points>1</Points>
                                                    <div>Copy the code shown below.</div>
                                                </AlignFlex>
                                                <AlignFlex>
                                                    <Points>2</Points>
                                                    <div>
                                                        Paste code into an HTML file where the
                                                        booking button should appear.
                                                    </div>
                                                </AlignFlex>
                                                <AlignFlex>
                                                    <Points>3</Points>
                                                    <div>Customers can now book you online.</div>
                                                </AlignFlex>
                                            </PointsContent>
                                        </div>
                                        <SyntaxHighlighter language="javascript">
                                            {getEmbededCode(
                                                _userData?.user?.business_id?._id!,
                                                btnBgColor,
                                                btnTextColor
                                            )}
                                        </SyntaxHighlighter>
                                    </Card>
                                </Flex>
                            </Row>
                        </Wrapper>
                    </Col>
                    <Col md={6} lg={5}>
                        <Wrapper>
                            <Row>
                                <Flex noGutter>
                                    <Card>
                                        <Heading>Widget Settings</Heading>
                                        <Text>
                                            You can customize the booking widget to match your brand
                                            colors.
                                        </Text>

                                        <CustomiseWidget
                                            btnBgColor={btnBgColor}
                                            btnTextColor={btnTextColor}
                                            setBtnBgColor={setBtnBgColor}
                                            setBtnTextColor={setBtnTextColor}
                                        />
                                    </Card>
                                </Flex>
                                <Flex noGutter>
                                    <Card>
                                        <Heading>Post-Booking Redirect</Heading>
                                        <Text>
                                            This can be used to send clients to a custom booking
                                            page. After a client books an appointment, they will be
                                            immediately sent to the Redirect URL.
                                        </Text>
                                        <SwitchContainer>
                                            <SwitchLabel>
                                                Redirect clients after appointment is booked.
                                            </SwitchLabel>
                                            <Switch
                                                value={bookingRedirect.enabled}
                                                onChange={(val: boolean) => {
                                                    setBookingRedirect((prevVal: any) => ({
                                                        ...prevVal,
                                                        enabled: val,
                                                    }));
                                                }}
                                            />
                                        </SwitchContainer>
                                        {bookingRedirect.enabled && (
                                            <RedirectUrlContainer>
                                                <Input
                                                    label="Redirect URL"
                                                    value={bookingRedirect.url}
                                                    onChange={({
                                                        name,
                                                        value,
                                                    }: {
                                                        name: string;
                                                        value: string;
                                                    }) => {
                                                        console.log('changing to:', value);
                                                        setBookingRedirect((prevVal: any) => ({
                                                            ...prevVal,
                                                            url: value,
                                                        }));
                                                    }}
                                                />
                                            </RedirectUrlContainer>
                                        )}
                                        {showUpdateButtonBookingRedirect && (
                                            <Button
                                                type="submit"
                                                bgtype="secondary"
                                                label="Save"
                                                disabled={loading}
                                                ifClicked={() => updateBookingRedirect()}></Button>
                                        )}
                                    </Card>
                                </Flex>
                            </Row>
                        </Wrapper>
                    </Col>
                </Row>
            )}
            {showRevokeTokenPrompt && (
                <WarningModal
                    message={`Are you sure you want to revoke your Square authentication token? If you do, you will not be able to charge any appointments that were scheduled while your Square account was connected.`}
                    onContinue={() => {
                        setShowRevokeTokenPrompt(false);
                        revokeSquareToken();
                    }}
                    onClose={() => {
                        setShowRevokeTokenPrompt(false);
                    }}></WarningModal>
            )}
            {showUpdateSquareConnectionPrompt && (
                <WarningModal
                    message={`Click "Continue" to update your Square connection's permissions and allow you to select different Locations from your Square account.`}
                    onContinue={() => {
                        setShowUpdateSquareConnectionPrompt(false);
                        connectToSquare();
                    }}
                    onClose={() => {
                        setShowUpdateSquareConnectionPrompt(false);
                    }}></WarningModal>
            )}
            {showUpdateSquareLocationPrompt && (
                <WarningModal
                    message={`Are you sure you want to change which Square location you use for future payments?`}
                    onContinue={() => {
                        setShowUpdateSquareLocationPrompt(false);
                        updateSquareLocationId(selectedSquareLocationId);
                    }}
                    onClose={() => {
                        setShowUpdateSquareLocationPrompt(false);
                    }}></WarningModal>
            )}
        </Wrapper>
    );
};
const Wrapper = styled(Container)`
    padding: 0rem;
`;

const Card = styled.div`
    background-color: white;
    padding: 1.75rem 2rem;
    border-radius: 0.5rem;
`;
const CardBody = styled.div`
    margin-bottom: 1.5rem !important;
`;

const AlertInfo = styled.div`
    border-left: 0.3rem solid #0894e2;
    background: rgba(8, 148, 226, 0.038);
    padding: 14px;
    border-radius: 4px;
`;

const AlertInfoHeading = styled.div`
    color: #0894e2;
    font-weight: bold;
    text-transform: capitalize;
`;

const AlertInfoText = styled.div`
    font-size: 14px;
    padding-top: 4px;
`;

const ConnectAccountButton = styled.button`
    cursor: pointer;
    padding: 0;
    margin: 5px;
    font-weight: 600;
    color: #fff;
    border-radius: 8px;
    font-size: 13px;
    background-color: #000;
    width: 150px;
    height: 50px;
`;
const RemoveAccount = styled.button`
    cursor: pointer;
    padding: 13px 26px;
    text-align: center;
    margin: 5px;
    width: 150px;
    height: 50px;
    background: #eb5353 !important;
    color: #fff;
    border-radius: 8px;
`;
const Heading = styled.div`
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    font-weight: 600 !important;
`;

const Text = styled.p`
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    color: rgba(157, 160, 164) !important;
    margin: 0rem;
`;
const Flex = styled(Col)`
    margin-bottom: 1.2rem;
`;
const FlexSection = styled.div`
    display: flex;
`;
const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const SwitchLabel = styled.div`
    font-weight: 400 !important;
`;

const RedirectUrlContainer = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const PointsContent = styled.div`
    padding: 1.25rem 0rem !important;
`;

const Points = styled.div`
    color: rgba(255, 255, 255) !important;
    background-color: #1a202c;
    border-radius: 9999px !important;
    justify-content: center !important;
    align-items: center !important;
    width: 2rem !important;
    height: 2rem !important;
    display: flex !important;
    margin-right: 0.5rem;
`;

const AlignFlex = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem !important;
`;

const UpdateAccountButton = styled.button`
    cursor: pointer;
    padding: 13px 26px;
    text-align: center;
    margin: 5px;
    width: 150px;
    height: 50px;
    background: rgb(35, 134, 54) !important;
    color: #fff;
    border-radius: 8px;
`;

export default OnlineBooking;
